﻿import LocalStorage from '/resourcepackages/SDS2022/assets/dist/js/Local-storage.min.js';
const storage = new LocalStorage();

function addToGTMDataLayer(data) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
}
function toggleHeart(obj, modelId) { 
    if (storage.getIndexByModelId(modelId) === -1) {
        obj.classList.remove("fill-pink");
        obj.classList.add("fill-transparent");
    } else {
        obj.classList.remove("fill-transparent");
        obj.classList.add("fill-pink");
    }
}
function favoriteEvent(e) {
    const model = e.dataset.model;
    const modelId = e.dataset.modelid;
    const url = document.getElementById(`link-for-frame-${model}`).href;
    const title = document.getElementById(`title-for-frame-${model}`).innerText;
    const price = document.getElementById(`price-for-frame-${model}`).innerText;
    const company = document.getElementById(`company-for-frame-${model}`).innerText;
    const thumbnailUrl = document.getElementById(`img-for-frame-${model}`).src;
    const data = { 
        modelId: modelId,
        modelCode : model,
        title: title,
        colorCode:"",
        modelPrice: price,
        url: url,
        company: company
    };

    if (storage.getIndexByModelId(modelId) === -1) {
        storage.create(data);
         addToGTMDataLayer({
            event: "favoriteHeartClicked"
        });

    } else {
        storage.remove(data);
    }
    toggleHeart(e.getElementsByTagName('svg')[0], modelId); 
}
function checkedEvent(e){
    const modelId = e.id;
    const model= e.dataset.model;
    const modelColors =  document.querySelectorAll(`[data-model='${model}']`);

    for(let index = 0; index < modelColors.length; index++){
        if (modelColors[index].id === modelId)
            continue;
        modelColors[index].checked  = false;
    }
 
    if (e.checked) {
        const favorite = document.getElementById(`favorite-for-frame-${model}`);
        document.getElementById(`link-for-frame-${model}`).href = e.dataset.targeturl;
        document.getElementById(`img-for-frame-${model}`).src = e.dataset.thumbnailurl;
        favorite.dataset.modelid = modelId; 
        toggleHeart(favorite.getElementsByTagName('svg')[0] ,modelId);
    }
}

function initSimilarCheckBoxes(){
    const checkBoxes = document.getElementsByClassName("scc");

    for (let i = 0; i < checkBoxes.length; i++) {
        checkBoxes[i].addEventListener('change',() => { checkedEvent(checkBoxes[i]) }, false);
    } 
}

function initFavoriteButtons(){
    const buttons = document.getElementsByClassName("add-favorite-btn");
    for (let i = 0; i < buttons.length; i++) {
        buttons[i].addEventListener('click',() => { favoriteEvent(buttons[i]) }, false);
        toggleHeart(buttons[i].getElementsByTagName('svg')[0], buttons[i].dataset.modelid);
    }
}


(function () { 
    initSimilarCheckBoxes();
    initFavoriteButtons();
})();